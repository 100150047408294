import styled from 'styled-components';
import { Text } from '@naf/text';
import { GridCol, GridRow } from '@naf/grid';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 100px 0;
`;

const AnimatedText = styled(Text)`
  &::after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\\2026'; /* ascii code for the ellipsis character */
    width: 0;
  }

  @keyframes ellipsis {
    to {
      width: 25px;
    }
  }

  @keyframes ellipsis {
    to {
      width: 25px;
    }
  }
`;

const Container = styled.div`
  p {
    margin: 0;
  }

  b {
    font-weight: bold;
  }

  img {
    margin-bottom: ${spacing.space40};
  }
`;

const SummaryCol = styled(GridCol)`
  grid-column-start: 9;

  @media (max-width: ${breakpoints.m}) {
    grid-column-start: auto;
    margin-top: ${spacing.space40};
  }
`;

const Summary = styled.div`
  background-color: ${nafColor.signature.yellow10};
  padding: ${spacing.space32};

  h3 {
    margin: 0;
  }
`;

const Products = styled.div`
  padding: ${spacing.space32} 0;
  border-bottom: 1px solid rgb(26 26 26 / 12%);

  h5 {
    margin: 0;
  }
`;

const Total = styled.div`
  padding-top: ${spacing.space32};
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

const ProductLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  &:not(:last-child) {
    padding-bottom: ${spacing.space32};
  }

  h5:last-child {
    white-space: nowrap;
    text-align: right;
    min-width: 70px;
  }
`;

const Header = styled(Text)`
  margin-bottom: ${spacing.space64};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ol {
    list-style: none;
    counter-reset: item;
    margin-top: 48px;
    margin-left: 0;
    padding-left: 0;
  }

  li {
    counter-increment: item;
    position: relative; /* so that pseudoelements are positioned relatively to their "li"s */
    margin-bottom: 0; /* This overrides previously specified margin-bottom */
    p {
      position: relative;
      top: -34px;
      padding-left: 66px;
      vertical-align: middle;
      display: inline-block;
      margin: 0;
    }

    ::before {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      margin-right: 10px;
      content: counter(item);
      background: #d3eac2;
      color: #062e2a;
      text-align: center;
      font-weight: 400;
    }

    ::after {
      content: '';
      position: absolute;
      left: 19px; /* adjust manually */
      height: 100%;
      width: 3px;
      z-index: -1;
      background-image: linear-gradient(to bottom, #062e2a 33%, rgb(255 255 255 / 0%) 0%);
      background-position: left;
      background-size: 3px 10px;
      background-repeat: repeat-y;
    }

    :first-child::after {
      top: 6px;
    }

    :last-child::after {
      height: 0;
    }
  }
`;

const LinkWrapper = styled(GridRow)`
  margin-top: ${spacing.space80};
`;

export default {
  LoaderWrapper,
  AnimatedText,
  Container,
  SummaryCol,
  Summary,
  Products,
  Total,
  ProductLine,
  Header,
  Wrapper,
  LinkWrapper,
};
